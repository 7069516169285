import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Button, Modal } from "react-bootstrap";
import { useAccount } from 'wagmi'
import { Tweet } from 'react-tweet';
import CreatableSelect from 'react-select/creatable';
import { format, parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import TokenFilter from '../components/TokenFilterNew';
import InfluencerFilter from '../components/InfluencerFilter';
import KeywordFilter from '../components/KeywordFilter';
import { transformInfluencersToCategories, transformTokensToCategories } from '../components/utils';
import { Influencer, InfluencerCategory, Token, TokenCategory } from '../components/filterTypes';
import jsonData from '../components/jsonformatter.json';
import no_img from "../assets/images/no-image-icon.png"
import './admin.css'

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};



interface TokenFilterInterface {
    token_name: string;
    token_symbol: string;
    ecosystem: string[];
    chain_id: string;
    id: string;
}

interface SelectCryptocurrencyOption {
    value: string;
    label: string;
    details: {
        ecosystem?: string;
        chainId?: string;
    };
}



interface SelectOption {
    value: string;
    label: string;
}


interface Tweet {
    user: number;
    tweet_id: string;
    created_at: string;
    cryptocurrency: string;
    crypto_symbol: string;
    classification: string;
    TLDR: string;
    category: string;
    keyword: string;
    user_profile_image_url: string;
    user_username: string;
    dexscreen_url: string;
    token_sniffer_url: string;
    id: string;
    priceUsd: string;
    ticker_id: string;
    curated: boolean;
}
interface ViewTweet {
    complete_text: string;
    tweet_id: string;
    publishable: boolean;
    user_profile_image_url: string;
    user_name: string;
    username: string;
    created_at: string;
}

interface FormTickerData {
    token_name: string;
    coin_id: string;
    image_url: string;
    token_symbol: string;
    description: string;
    token_address: string;
    website: string;
    telegram_channel_identifier: string;
    twitter_screen_name: string;
    coingecko_url: string;
    dexscreen_url: string;
    chain_id: string;
    categories: { value: string; label: string }[];
    mmt_categories: { value: string; label: string }[];
    ecosystem: { value: string; label: string }[];
    dappradar_url: string;
    defillama_url: string;
    discord_url: string;
    tokenterminal_url: string;
    verified: boolean,
    fetch_status: boolean,
    asset_platform_id: string;
}
interface SelectAssertPlatformIdOption {
    value: string;
    label: string;
}
interface Category {
    value: string;
    label: string;
}

interface Ecosystem {
    name: string;
    address: string;
    dexscreen_url: string;
}

export const TweetList = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const { address, isConnected } = useAccount()
    const [activeAllTweetsTimeWindow, setActiveAllTweetsTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)

    const [allTweets, setAllTweets] = useState<Tweet[]>([]);
    const [allTweetloader, setAllTweetloader] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDatetime, setStartDatetime] = useState<Date | null>(new Date(new Date().setDate(new Date().getDate() - 7))); // Default to one week ago
    const [endDatetime, setEndDatetime] = useState<Date | null>(new Date());


    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    const [tokenFilter, setTokenFilter] = useState<TokenFilterInterface[]>([]);
    const [tokenFilter1, setTokenFilter1] = useState<Token[]>([]);
    const [influencerFilter, setInfluencerFilter] = useState<InfluencerCategory[]>([]);
    const [searchTweetId, setSearchTweetId] = useState('');
    // const [categoryFilter, setCategoryFilter] = useState<TokenFilterInterface[]>([]);
    // const [tokenFilter, setTokenFilter] = useState<TokenFilterInterface[]>([]);

    const [filteredTokens, setFilteredTokens] = useState<string[]>([]);
    const [filteredTokens1, setFilteredTokens1] = useState<string[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);


    const [selectedToken, setSelectedToken] = useState<Tweet | null>(null);
    const [updatedDescription, setUpdatedDescription] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const [showTweetModal, setShowTweetModal] = useState(false);
    const [userSelectedTweet, setUserSelectedTweet] = useState<ViewTweet | null>(null);
    const [viewTweetId, setViewTweetId] = useState();

    const [options, setOptions] = useState<SelectCryptocurrencyOption[]>([]);
    const [keywordOptions, setKewordOptions] = useState<SelectOption[]>([]);

    const [selectedTicker, setSelectedTicker] = useState('');
    const [showTickerUpdateModal, setShowTickerUpdateModal] = useState(false);
    const [formTickerData, setFormTickerData] = useState<FormTickerData>({
        token_name: '',
        coin_id: '',
        image_url: '',
        token_symbol: '',
        description: '',
        token_address: '',
        website: '',
        telegram_channel_identifier: '',
        twitter_screen_name: '',
        coingecko_url: '',
        dexscreen_url: '',
        chain_id: '',
        categories: [],
        mmt_categories: [],
        ecosystem: [],
        dappradar_url: '',
        defillama_url: '',
        discord_url: '',
        tokenterminal_url: '',
        verified: false,
        fetch_status: true,
        asset_platform_id: ''
    });
    const [assetPlatformIdOptions, setAssetPlatformIdOptions] = useState<SelectAssertPlatformIdOption[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<Category[]>([]);
    const [ecosystemsOptions, setEcosystemsOptions] = useState<Category[]>([]);
    const [ecosystems, setEcosystems] = useState<Ecosystem[]>([]);
    const [mmtCategoriesOptions, setMmtCategoriesOptions] = useState<Category[]>([]);

    const [formData, setFormData] = useState({
        cryptocurrency: '',
        crypto_symbol: '',
        classification: '',
        category: '',
        keyword: '',
        priceUsd: '',
        curated: false,
        ticker_id: '',
    });

    const handleKeywordSelectionChange = (selectedValues: string[]) => {
        setFilteredKeywords(selectedValues)
    };

    const handleTokenSelectionChange = (selectedValues: string[]) => {
        setFilteredTokens(selectedValues)
    };

    const handleTokenSelectionChange1 = (selectedValues: string[]) => {
        setFilteredTokens1(selectedValues)
    };

    const handleSelectedItemsChange = (selectedItems: string[]) => {
        setSelectedInfluencers(selectedItems);
    };

    const [showTweetUpdateModal, setShowTweetUpdateModal] = useState(false);



    const formatDateToUTCWithoutZ = (date: any) => {
        const utcDate = new Date(date).toISOString(); // This will give you the date in ISO format with 'Z'
        return utcDate.replace('Z', ''); // This removes the 'Z'
    };


    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);


    const edit_tweets = (token: Tweet) => {
        console.log(token)
        setSelectedToken(token);
        setFormData({
            cryptocurrency: token.cryptocurrency || '',
            crypto_symbol: token.crypto_symbol || '',
            classification: token.classification || '',
            category: token.category || '',
            keyword: token.keyword || '',
            priceUsd: token.priceUsd || '',
            curated: token.curated || false,
            ticker_id: token.ticker_id
        });
        // setUpdatedDescription(token.description || ''); // Initialize with existing description or empty
        setShowTweetUpdateModal(true);
    };


    const handleTokenChange = (selectedOption: any) => {
        if (!selectedOption) {
            // Handle null case: Reset everything
            setFormData({
                ...formData,
                cryptocurrency: '',
                crypto_symbol: '',
                ticker_id: ''
            });
        } else if (selectedOption.label === 'Select a cryptocurrency') {
            setFormData({
                ...formData,
                cryptocurrency: '',
                crypto_symbol: '',
                ticker_id: ''
            });
        } else {
            const matchedToken = tokenFilter.find(token => token.id === selectedOption.value);
            setFormData({
                ...formData,
                cryptocurrency: selectedOption.label,
                crypto_symbol: matchedToken ? matchedToken.token_symbol : '',
                ticker_id: selectedOption.value
            });
        }
    };


    const handleCategoryChange = (selectedOption: any) => {
        setFormData({
            ...formData,
            category: selectedOption.value,
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSaveChanges = () => {
        if (selectedToken) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}admin/tweets/${selectedToken.id}?wallet_id=${address}`;

            const payload = {
                ...formData
            };

            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowTweetUpdateModal(false);
                    toast.success('Ticker description updated successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });
                    get_all_tweets(currentPage, filteredTokens1, filteredKeywords, selectedInfluencers, '');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to update ticker description', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    } else {
                        toast.error('Failed to update ticker description', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    }
                });
        }
    };


    const get_all_tweets = async (page: any, token: any, keyword: any, influencer: any, category: any) => {
        setAllTweetloader(true);

        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const url = `${process.env.REACT_APP_API_URL}admin/tweets?page=${page}&wallet_id=${walletIdParam}&time_window=${activeAllTweetsTimeWindow}`;

        const requestBody = {
            category: category ? category.join(',') : null,
            token: token ? token.join(',') : null,
            influencer: influencer ? influencer.join(',') : null,
            keyword: keyword ? keyword.join(',') : null,
            tweet_id: searchTweetId ? searchTweetId : null
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllTweets(data['data']);
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
            } else {
                console.log(response.status);
                setAllTweets([]);
                setTotalPages(0)
            }
        } catch (e) {
            console.log(e);
            setAllTweetloader(false);
            setAllTweets([]);
            setTotalPages(0)
        } finally {
            setAllTweetloader(false);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };

    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "admin/ticker-dropdown?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTokenFilter(data['data']['tokens']);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();

                setKeywordFilter(data['filters']['keywords']);
                const categoriesWithInfluencers = transformInfluencersToCategories(data['filters']['influencer']);
                const categoriesWithTokens = transformTokensToCategories(data['filters']['tokens']);
                const mmtoptions = data['filters']['mmt_categories'].map((mmt_category: any) => ({
                    value: mmt_category,
                    label: mmt_category
                }));
                setMmtCategoriesOptions(mmtoptions)
                console.log(categoriesWithTokens)
                // Set the state with the formatted data
                setTokenFilter1(data['filters']['tokens']);
                setInfluencerFilter(categoriesWithInfluencers);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const fetchTickerDetails = async (cryptoSymbol: any) => {
        setSelectedTicker(cryptoSymbol);
        const url = `${process.env.REACT_APP_API_URL}admin/ticker/${cryptoSymbol}?wallet_id=${address}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "content-type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data); // Handle the data as needed
            let token = data['data'];
            const categories = token?.categories?.map((cat: string) => ({ value: cat, label: cat })) || [];
            const ecosystems = token?.ecosystem?.map((cat: string) => ({ value: cat, label: cat })) || [];

            if (token?.ecosystem) {
                const isArrayOfStrings = typeof token.ecosystem[0] === 'string';

                setEcosystems(token.ecosystem.map((eco: any) => {
                    if (isArrayOfStrings) {
                        return {
                            name: eco,                 // Use the string as the name
                            address: '',               // Set default or fetched address here
                            dexscreen_url: ''          // Set default or fetched dexscreen_url here
                        };
                    } else {
                        return {
                            name: eco.name,            // Assuming the object has a name property
                            address: eco.address,      // Assuming the object has an address property
                            dexscreen_url: eco.dexscreen_url // Assuming the object has a dexscreen_url property
                        };
                    }
                }));
            } else {
                setEcosystems([]);
            }

            const mmt_categories = token?.mmt_categories?.map((cat: string) => ({ value: cat, label: cat })) || [];
            setFormTickerData({
                token_name: token?.token_name || '',
                coin_id: token?.coin_id || '',
                image_url: token?.image_url || '',
                token_symbol: token?.token_symbol || '',
                description: token?.description || '',
                token_address: token?.token_address || '',
                website: token?.website || '',
                telegram_channel_identifier: token?.telegram_channel_identifier || '',
                twitter_screen_name: token?.twitter_screen_name || '',
                coingecko_url: token?.coingecko_url || '',
                dexscreen_url: token?.dexscreen_url || '',
                chain_id: token?.chain_id || '',
                categories: categories,
                ecosystem: ecosystems,
                mmt_categories: mmt_categories,
                dappradar_url: token?.dappradar_url || '',
                defillama_url: token?.defillama_url || '',
                discord_url: token?.discord_url || '',
                tokenterminal_url: token?.tokenterminal_url || '',
                verified: token?.verified || false,
                fetch_status: token?.fetch_status || false,
                asset_platform_id: token?.asset_platform_id || ''
            });

            setUpdatedDescription(token?.description || ''); // Initialize with existing description or empty

            setShowTickerUpdateModal(true)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const fetchCategories = async () => {
        const url = `${process.env.REACT_APP_API_URL}admin/ticker/categories?wallet_id=${address}`;

        try {
            const response = await fetch(url, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();
                const catoptions = data.data['categories'].map((category: any) => ({
                    value: category,
                    label: category
                }));
                setCategoryOptions(catoptions);
                const ecooptions = data.data['ecosystems'].map((ecosystems: any) => ({
                    value: ecosystems,
                    label: ecosystems
                }));
                setEcosystemsOptions(ecooptions);
            } else {
                console.error('Error fetching categories:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };


    const handleSaveTickerChanges = () => {


        if (selectedTicker != '') {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}admin/ticker/${selectedTicker}?wallet_id=${address}`;

            // Transform the categories array
            const transformedCategories = formTickerData.categories.map((category: Category) => category.value);
            // const transformedEcosystems = formTickerData.ecosystem.map((ecosystem: Category) => ecosystem.value);
            const transformedMMTCategories = formTickerData.mmt_categories.map((mmt_category: Category) => mmt_category.value);

            const payload = {
                ...formTickerData,
                categories: transformedCategories,
                ecosystem: ecosystems,
                mmt_categories: transformedMMTCategories
            };
            console.log(payload)


            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowTickerUpdateModal(false);
                    toast.success('Ticker updated successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to update ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    } else {
                        toast.error('Failed to update ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    }
                });
        }
    };

    const view_tweet = async (tweet_id: any) => {
        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet_id];
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setViewTweetId(tweet_id);
                setShowTweetModal(true)
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }

    }
    const open_tweet_link = (tweet_id: string) => {
        console.log(tweet_id)
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const handleTweetSearch = (query: any) => {
        setSearchTweetId(query);
        // Implement your search logic here
    };


    const convertToLocalTime = (isoDate: any) => {
        isoDate = isoDate + 'Z'
        const date = new Date(isoDate);
        return format(date, 'dd/MM/yyyy, hh:mm:ss a');
    };

    const handleTickerCategoryChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setFormTickerData(prevState => ({
            ...prevState,
            categories: selectedOptions as Category[]
        }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e.target.value)
        const { id, value } = e.target;
        setFormTickerData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSelectChange = (selectedOption: any) => {
        console.log(selectedOption.value)
        setFormTickerData((prevState) => ({
            ...prevState,
            asset_platform_id: selectedOption.value,
        }));
    };

    const handleCreateAssetPlatformId = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setAssetPlatformIdOptions(prevOptions => [...prevOptions, newCategory]);
        setFormTickerData((prevState) => ({
            ...prevState,
            asset_platform_id: inputValue,
        }));
    };

    const handleCreateCategory = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setCategoryOptions(prevOptions => [...prevOptions, newCategory]);
        setFormTickerData(prevState => ({
            ...prevState,
            categories: [...prevState.categories, newCategory]
        }));
    };


    const handleEcosystemChange = (index: any, field: any, selectedOption: any) => {
        // setFormTickerData(prevState => ({
        //     ...prevState,
        //     ecosystem: selectedOptions as Category[]
        // }));
        console.log(index, field, selectedOption.value)
        const updatedEcosystems = ecosystems.map((ecosystem, i) =>
            i === index ? { ...ecosystem, [field]: selectedOption.value } : ecosystem
        );
        setEcosystems(updatedEcosystems);

    };

    const handleEcosystemChangeInTable = (index: any, field: any, selectedOption: any) => {
        const updatedEcosystems = ecosystems.map((ecosystem, i) =>
            i === index ? { ...ecosystem, [field]: selectedOption.value } : ecosystem
        );
        setEcosystems(updatedEcosystems);
    };


    const handleMMTCategoryChange = (selectedOptions: MultiValue<Category>, actionMeta: ActionMeta<Category>) => {
        setFormTickerData(prevState => ({
            ...prevState,
            mmt_categories: selectedOptions as Category[]
        }));
    };

    const handleCreateEcosystem = (inputValue: string) => {
        const newEcosystem = { value: inputValue, label: inputValue };
        setEcosystemsOptions(prevOptions => [...prevOptions, newEcosystem]);
        setFormTickerData(prevState => ({
            ...prevState,
            ecosystem: [...prevState.ecosystem, newEcosystem]
        }));
    };


    const handleCreateMMTCategory = (inputValue: string) => {
        const newCategory = { value: inputValue, label: inputValue };
        setMmtCategoriesOptions(prevOptions => [...prevOptions, newCategory]);
        setFormTickerData(prevState => ({
            ...prevState,
            mmt_categories: [...prevState.mmt_categories, newCategory]
        }));
    };

    const formatCryptocurrencyOptionLabel = ({ label, details }: SelectCryptocurrencyOption) => (
        <div>
            <div>{label}</div>
            <div style={{ fontSize: '0.9em', color: '#888' }}>
                {details.ecosystem && (
                    <div style={{ color: '#aeaeae' }}>
                        Ecosystem: {details.ecosystem}
                    </div>
                )}
                {details.chainId && (
                    <div style={{ color: '#aeaeae' }}>
                        Chain ID: {details.chainId}
                    </div>
                )}
            </div>
        </div>
    );


    const getCoingeckoAndDexscreenData = async (platform: any, platform_id: any) => {
        const url = `${process.env.REACT_APP_API_URL}admin/ticker-checker?wallet_id=${address}&platform=${platform}&platform_id=${platform_id}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();


                setFormTickerData((prevFormData) => {
                    let update_data: Partial<FormTickerData> = {};

                    if (data.data.token_name && data.data.token_name !== null) update_data.token_name = data.data.token_name;
                    if (data.data.coin_id && data.data.coin_id !== null) update_data.coin_id = data.data.coin_id;
                    if (data.data.image_url && data.data.image_url !== null) update_data.image_url = data.data.image_url;
                    if (data.data.token_symbol && data.data.token_symbol !== null) update_data.token_symbol = data.data.token_symbol;
                    if (data.data.description && data.data.description !== null) update_data.description = data.data.description;
                    if (data.data.website && data.data.website !== null) update_data.website = data.data.website;
                    if (data.data.telegram_channel_identifier && data.data.telegram_channel_identifier !== null) update_data.telegram_channel_identifier = data.data.telegram_channel_identifier;
                    if (data.data.twitter_screen_name && data.data.twitter_screen_name !== null) update_data.twitter_screen_name = data.data.twitter_screen_name;
                    if (data.data.coingecko_url && data.data.coingecko_url !== null) update_data.coingecko_url = data.data.coingecko_url;
                    if (data.data.asset_platform_id && data.data.asset_platform_id !== null) update_data.asset_platform_id = data.data.asset_platform_id;


                    // Map categories to the expected format
                    if (data.data.categories && data.data.categories.length > 0 && data.data.categories !== null) {
                        update_data.categories = data.data.categories.map((cat: string) => ({ value: cat, label: cat }));
                    }


                    if (data.data.ecosystem && data.data.ecosystem.length > 0 && data.data.ecosystem !== null) {
                        setEcosystems(data.data.ecosystem)
                    }

                    // Adding the additional fields
                    if (data.data.token_address && data.data.token_address !== null) update_data.token_address = data.data.token_address;
                    if (data.data.chain_id && data.data.chain_id !== null) update_data.chain_id = data.data.chain_id;
                    if (data.data.dexscreen_url && data.data.dexscreen_url !== null) update_data.dexscreen_url = data.data.dexscreen_url;

                    update_data.verified = data.data.verified !== null ? data.data.verified : prevFormData.verified;
                    update_data.fetch_status = data.data.fetch_status !== null ? data.data.fetch_status : prevFormData.fetch_status;

                    toast.success('Data successfully fetched', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });


                    // Return the merged object
                    return {
                        ...prevFormData,
                        ...update_data
                    };
                });

            } else {
                console.error('Error fetching data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchAllDexscreenData = async () => {
        for (let ecosystem of ecosystems) {
            if (ecosystem.address) {
                const url = `${process.env.REACT_APP_API_URL}admin/ticker-checker?wallet_id=${address}&platform=ds&platform_id=${ecosystem.address}`;
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        // Handle 500 or other server errors
                        throw new Error(`Server error: ${response.status}`);
                    }

                    const data = await response.json();
                    if (!data || !data.data) {
                        // Handle unexpected data structure
                        throw new Error('Unexpected API response structure');
                    }


                    setEcosystems(prevEcosystems =>
                        prevEcosystems.map(e =>
                            e.address === ecosystem.address
                                ? { ...e, dexscreen_url: data.data.dexscreen_url || e.dexscreen_url }
                                : e
                        )
                    );

                    toast.success('Data successfully fetched for ' + ecosystem.name, {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });

                    // You can handle the fetched data here (e.g., update the ecosystem with the fetched data)
                } catch (error) {
                    console.error(`Error fetching data for ${ecosystem.name}:`, error);
                    setEcosystems(prevEcosystems =>
                        prevEcosystems.map(e =>
                            e.address === ecosystem.address
                                ? { ...e, dexscreen_url: '' }
                                : e
                        )
                    );
                }
            }
        }
    };

    const handleAddEcosystem = () => {
        setEcosystems([...ecosystems, { name: '', address: '', dexscreen_url: '' }]);
    };

    const handleDeleteEcosystem = (index: any) => {
        const updatedEcosystems = ecosystems.filter((_, i) => i !== index);
        setEcosystems(updatedEcosystems);
    };


    useEffect(() => {
        if (tokenFilter) {
            const formattedOptions = tokenFilter.map((token) => {
                const ecosystems = token.ecosystem && token.ecosystem.length > 0 ? token.ecosystem.join(', ') : undefined;
                const chainId = token.chain_id ? token.chain_id : undefined;

                return {
                    value: token.id,
                    label: `${token.token_name} (${token.token_symbol})`,
                    details: {
                        ecosystem: ecosystems,
                        chainId: chainId,
                    },
                };
            });

            // Prepend the "empty field" option
            setOptions([{ value: '', label: 'Select a cryptocurrency', details: {} }, ...formattedOptions]);
        }
    }, [tokenFilter]);

    useEffect(() => {
        if (keywordFilter) {
            const formattedKeywordOptions = keywordFilter.map((keyword) => ({
                value: keyword,
                label: keyword
            }));
            setKewordOptions(formattedKeywordOptions);
        }
    }, [keywordFilter]);


    useEffect(() => {
        get_all_tweets(currentPage, filteredTokens1, filteredKeywords, selectedInfluencers, '');
    }, [currentPage, filteredTokens1, filteredKeywords, selectedInfluencers, activeAllTweetsTimeWindow, searchTweetId]);


    useEffect(() => {
        get_all_token_filter('1month');
        get_token_filter('1month')
        fetchCategories();
    }, []);

    useEffect(() => {
        // Parse JSON data and create options for react-select
        const parsedOptions = jsonData.map((item) => ({
            value: item.coingecko_asset_platform_id,
            label: item.coingecko_asset_platform_id,
        }));
        setAssetPlatformIdOptions(parsedOptions);
    }, []);

    return (
        <div className="container-fluid">
            <Toaster />
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">
                    <Row>

                        {/* <Col sm={12} className="mt-3">
                        <Card className="t-w-card">
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={12} lg={3}>
                                        <div className="filter-label">Filter by Token</div>
                                        <TokenFilter tokens={tokenFilter} onSelectedItemsChange={handleTokenSelectionChange} />
                                    </Col>



                                </Row>
                            </Card.Body>
                        </Card>
                    </Col> */}

                        <Col sm={12} className="mt-3">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={12} lg={4}>
                                            <div className="filter-label">Filter by Token</div>
                                            <TokenFilter tokens={tokenFilter1} onSelectedItemsChange={handleTokenSelectionChange1} />
                                        </Col>
                                        <Col sm={12} md={12} lg={4}>
                                            <div className="filter-label">
                                                Filter by Influencer
                                            </div>
                                            <InfluencerFilter influencers={influencerFilter} onSelectedItemsChange={handleSelectedItemsChange} />

                                        </Col>
                                        <Col sm={12} md={12} lg={4}>
                                            <div className="filter-label">
                                                Filter by Keywords
                                            </div>
                                            <KeywordFilter keywords={keywordFilter} onSelectionChange={handleKeywordSelectionChange} />

                                        </Col>
                                        <Col sm={12} md={12} lg={4} className='mt-4'>
                                            <div className="filter-label">
                                                Filter by Tweet Id
                                            </div>

                                            <input
                                                type="text"
                                                className="contract-address-search"
                                                placeholder="Enter tweet id"
                                                value={searchTweetId}
                                                onChange={(e) => handleTweetSearch(e.target.value)}
                                            />
                                        </Col>
                                        <Col sm={12} md={12} lg={4} className='mt-4'>
                                            <div className="filter-label mt-1">
                                                Time Window
                                            </div>
                                            <div className='time-window-container d-flex'>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('15min')} className={`time-window ${activeAllTweetsTimeWindow == '15min' ? 'active' : ''}`}>
                                                    15 min
                                                </div>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('1hr')} className={`time-window ${activeAllTweetsTimeWindow == '1hr' ? 'active' : ''}`}>
                                                    1h
                                                </div>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('4hr')} className={`time-window ${activeAllTweetsTimeWindow == '4hr' ? 'active' : ''}`}>
                                                    4h
                                                </div>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('12hr')} className={`time-window ${activeAllTweetsTimeWindow == '12hr' ? 'active' : ''}`}>
                                                    12h
                                                </div>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('1day')} className={`time-window ${activeAllTweetsTimeWindow == '1day' ? 'active' : ''}`}>
                                                    1d
                                                </div>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('1week')} className={`time-window ${activeAllTweetsTimeWindow == '1week' ? 'active' : ''}`}>
                                                    7d
                                                </div>
                                                <div onClick={() => setActiveAllTweetsTimeWindow('1month')} className={`time-window ${activeAllTweetsTimeWindow == '1month' ? 'active' : ''}`}>
                                                    30d
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={4} className='mt-4'>
                                            <div>
                                                <div className='notes'>
                                                    <Icon.CheckCircleFill className='verified'></Icon.CheckCircleFill> -  Means Curated by admin
                                                </div>
                                                <div className='notes'>
                                                    <Icon.XCircleFill className='not-verified'></Icon.XCircleFill> - Means Not Curated by admin
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} className='mt-3'>

                            {allTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allTweetloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Tokens</th>
                                                    <th>Price</th>
                                                    <th>Tldr</th>
                                                    <th>Date</th>
                                                    <th>Category</th>
                                                    <th>Classification</th>
                                                    <th> Keyword</th>
                                                    <th></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allTweets.length > 0 ? (
                                                    allTweets.map((tweets, index) => (
                                                        <tr key={index} className={allTweets.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td width={'2%'}>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {index + 1}
                                                                    {tweets['curated'] ? (
                                                                        <Icon.CheckCircleFill className='verified'></Icon.CheckCircleFill> // Tick symbol for verified
                                                                    ) : (
                                                                        <Icon.XCircleFill className='not-verified'></Icon.XCircleFill> // Cross symbol for not verified
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td width={'10%'} className='cursor-pointer'>
                                                                <div className='d-flex align-items-center'>

                                                                    <div className="ms-1 ticker-name">{tweets['cryptocurrency']} </div>
                                                                    <div className="ms-1 ticker-symbol">{tweets['crypto_symbol'] ? tweets['crypto_symbol'] : ''}</div>
                                                                    {(tweets['cryptocurrency'] && tweets['crypto_symbol']) &&
                                                                        <Button
                                                                            variant="link"
                                                                            className="ms-1"
                                                                            onClick={() => fetchTickerDetails(tweets['ticker_id'])}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td width={'5%'}>{tweets['priceUsd'] ? '$ ' + tweets['priceUsd'] : ''}</td>
                                                            <td width={'30%'}>{tweets['TLDR']} <span className='view-tweet-link' onClick={() => view_tweet(tweets['tweet_id'])}>View Tweet</span></td>
                                                            <td width={'10%'}>
                                                                {convertToLocalTime(tweets['created_at'])}

                                                            </td>
                                                            <td width={'15%'}>{tweets['category']}</td>
                                                            <td width={'15%'}>{tweets['classification']}</td>
                                                            <td width={'15%'}>{tweets['keyword']}</td>


                                                            <td>
                                                                <div className="ctw-btn" onClick={() => edit_tweets(tweets)}>
                                                                    Edit
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div>
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                    <Modal className='tweet-modal' show={showTweetUpdateModal} onHide={() => setShowTweetUpdateModal(false)} centered>
                                        <Modal.Header className="justify-content-between">
                                            <div className='update-modal-heading'>Edit Tweet</div>
                                            <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetUpdateModal(false)}></Icon.XCircle>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mt-3">

                                                <label className='form-label ticker-text-label' htmlFor="cryptocurrency">Cryptocurrency:</label>
                                                <Select
                                                    id="cryptocurrency"
                                                    name="cryptocurrency"
                                                    options={options}
                                                    value={options.find(option => option.value === formData.ticker_id) || null}
                                                    onChange={handleTokenChange}
                                                    onFocus={() => {
                                                        // Clear the selected value on focus
                                                        handleTokenChange(null);
                                                    }}
                                                    className="ticker-text-box"
                                                    classNamePrefix="admin-select"
                                                    formatOptionLabel={formatCryptocurrencyOptionLabel}

                                                />
                                            </div>
                                            {/* <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="cryptocurrency">Cryptocurrency:</label>
                                                <input
                                                    type="text"
                                                    id="cryptocurrency"
                                                    name="cryptocurrency"
                                                    className="form-control ticker-text-box"
                                                    value={formData.cryptocurrency}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="crypto_symbol">Crypto Symbol:</label>
                                                <input
                                                    type="text"
                                                    id="crypto_symbol"
                                                    name="crypto_symbol"
                                                    className="form-control ticker-text-box"
                                                    value={formData.crypto_symbol}
                                                    onChange={handleInputChange}
                                                />
                                            </div> */}
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="classification">Classification:</label>
                                                <input
                                                    type="text"
                                                    id="classification"
                                                    name="classification"
                                                    className="form-control ticker-text-box"
                                                    value={formData.classification}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="category">Category:</label>

                                                <Select
                                                    id="category"
                                                    name="category"
                                                    options={keywordOptions}
                                                    value={keywordOptions.find(option => option.value === formData.category) || null}
                                                    onChange={handleCategoryChange}
                                                    className="ticker-text-box"
                                                    classNamePrefix="admin-select"
                                                />

                                            </div>
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="keyword">Keyword:</label>
                                                <input
                                                    type="text"
                                                    id="keyword"
                                                    name="keyword"
                                                    className="form-control ticker-text-box"
                                                    value={formData.keyword}
                                                    onChange={handleInputChange}
                                                />

                                                {/* <input
                                                    type="text"
                                                    id="keyword"
                                                    name="keyword"
                                                    className="form-control ticker-text-box"
                                                    value={formData.keyword}
                                                    onChange={handleInputChange}
                                                /> */}
                                            </div>
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="priceUsd">Price:</label>
                                                <input
                                                    type="text"
                                                    id="priceUsd"
                                                    name="priceUsd"
                                                    className="form-control ticker-text-box"
                                                    value={formData.priceUsd}
                                                    onChange={handleInputChange}
                                                />
                                            </div>

                                            <label className='token-description-label mt-3'>Curated:</label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="curated"
                                                    id="curated-true"
                                                    value="true"
                                                    checked={formData.curated === true}
                                                    onChange={() => setFormData({ ...formData, curated: true })}
                                                />
                                                <label className="token-description-label" htmlFor="curated-true">
                                                    True
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="curated"
                                                    id="curated-false"
                                                    value="false"
                                                    checked={formData.curated === false}
                                                    onChange={() => setFormData({ ...formData, curated: false })}
                                                />
                                                <label className="token-description-label" htmlFor="curated-false">
                                                    False
                                                </label>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-primary" onClick={handleSaveChanges} disabled={loading}>
                                                {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => setShowTweetUpdateModal(false)}>Close</button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal className='tweet-modal' show={showTweetModal} onHide={() => setShowTweetModal(false)} centered>
                                        <Modal.Header className="justify-content-between">
                                            <div></div>
                                            <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {viewTweetId && userSelectedTweet &&
                                                <div>
                                                    <div data-theme="dark" className='modal-tweet-container'>

                                                        {userSelectedTweet && userSelectedTweet.publishable ? (
                                                            <Tweet id={userSelectedTweet.tweet_id} />
                                                        ) : (
                                                            <div className="not-found-tweet-container">
                                                                <div className="d-flex align-items-center">
                                                                    <div>
                                                                        <img src={userSelectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{userSelectedTweet.user_name}</div>
                                                                        <div className="ms-1 ticker-symbol">@{userSelectedTweet.username}</div>

                                                                    </div>
                                                                </div>
                                                                <div className="tweet-complete-text">{userSelectedTweet.complete_text}</div>
                                                                <div className='token-detail-timestamp'>
                                                                    {
                                                                        convertToLocalTime(userSelectedTweet.created_at)
                                                                    }
                                                                </div>
                                                                <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(userSelectedTweet.tweet_id)}>
                                                                    Go To Tweet
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {viewTweetId &&
                                                <Button className='go-to-tweet-btn' variant="secondary" onClick={() => open_tweet_link(viewTweetId)}>
                                                    Go To Tweet
                                                </Button>
                                            }
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal className='tweet-modal modal-lg' show={showTickerUpdateModal} onHide={() => setShowTickerUpdateModal(false)} centered>
                                        <Modal.Header className="justify-content-between">
                                            <div className='update-modal-heading'>Edit Token</div>
                                            <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTickerUpdateModal(false)}></Icon.XCircle>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className='token-description-label' htmlFor="token_name">Token Name:</label>
                                                    <input
                                                        id="token_name"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.token_name}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="token_symbol">Token Symbol:</label>
                                                    <input
                                                        id="token_symbol"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.token_symbol}
                                                        onChange={handleChange}
                                                    />
                                                    <div className='d-flex align-items-center mt-2'>
                                                        <label className='token-description-label' htmlFor="coin_id">
                                                            Coin ID (Coingecko api id):
                                                        </label>
                                                        {formTickerData.coin_id && formTickerData.coin_id != null &&
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-secondary ms-2"
                                                                onClick={() => getCoingeckoAndDexscreenData("cg", formTickerData.coin_id)}
                                                            >
                                                                Fetch Data
                                                            </button>
                                                        }
                                                    </div>

                                                    <div className='notes'>* if data don't want fetch from api please add $MMT</div>
                                                    <input
                                                        id="coin_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.coin_id}
                                                        onChange={handleChange}
                                                    />


                                                    <label className='token-description-label mt-2' htmlFor="chain_id">Chain ID (Dexscreener api id):</label>
                                                    <input
                                                        id="chain_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.chain_id}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="asset_platform_id">Asset Platform ID (Geckoterminal api id):</label>
                                                    <input
                                                        id="asset_platform_id"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.asset_platform_id}
                                                        onChange={handleChange}
                                                    />
                                                    {/* <Select
                                                        id="asset_platform_id"
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                        value={assetPlatformIdOptions.find((option: any) => option.value === formData.asset_platform_id)}
                                                        onChange={handleSelectChange}
                                                        options={assetPlatformIdOptions}
                                                    /> */}
                                                    {/* <CreatableSelect
                                                        id="asset_platform_id"
                                                        value={assetPlatformIdOptions.find((option: any) => option.value === formTickerData.asset_platform_id)}
                                                        onChange={handleSelectChange}
                                                        onCreateOption={handleCreateAssetPlatformId}
                                                        options={assetPlatformIdOptions}
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    /> */}

                                                    <label className='token-description-label mt-2' htmlFor="image_url">Image URL:</label>
                                                    <input
                                                        id="image_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.image_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="description">Token Description:</label>
                                                    <textarea
                                                        id="description"
                                                        className="form-control token-description-text-area mt-3"
                                                        value={formTickerData.description}
                                                        onChange={handleChange}
                                                        style={{ height: '150px' }}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="token_address">Token Address:</label>
                                                    <input
                                                        id="token_address"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.token_address}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="dappradar_url">Dappradar Url:</label>
                                                    <input
                                                        id="dappradar_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.dappradar_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="defillama_url">Defillama Url:</label>
                                                    <input
                                                        id="defillama_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.defillama_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="discord_url">Discord Url:</label>
                                                    <input
                                                        id="discord_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.discord_url}
                                                        onChange={handleChange}
                                                    />

                                                </div>
                                                <div className="col-md-6">

                                                    <label className='token-description-label mt-2' htmlFor="tokenterminal_url">Tokenterminal Url:</label>
                                                    <input
                                                        id="tokenterminal_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.tokenterminal_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="website">Website:</label>
                                                    <input
                                                        id="website"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.website}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="telegram_channel_identifier">Telegram Channel Identifier:</label>
                                                    <input
                                                        id="telegram_channel_identifier"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.telegram_channel_identifier}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="twitter_screen_name">Twitter Screen Name:</label>
                                                    <input
                                                        id="twitter_screen_name"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.twitter_screen_name}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label' htmlFor="coingecko_url">Coingecko URL:</label>
                                                    <input
                                                        id="coingecko_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.coingecko_url}
                                                        onChange={handleChange}
                                                    />

                                                    <div className='d-flex align-items-center mt-2'>
                                                        <label className='token-description-label mt-2' htmlFor="dexscreen_url">Dexscreen URL:</label>
                                                        {formTickerData.token_address && formTickerData.token_address != null &&
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-secondary ms-2"
                                                                onClick={() => getCoingeckoAndDexscreenData("ds", formTickerData.token_address)}
                                                            >
                                                                Fetch Data
                                                            </button>
                                                        }
                                                    </div>
                                                    <input
                                                        id="dexscreen_url"
                                                        className="form-control mt-2 ticker-text-box"
                                                        value={formTickerData.dexscreen_url}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="categories">Coingecko Categories:</label>
                                                    <CreatableSelect
                                                        id="categories"
                                                        value={formTickerData.categories}
                                                        onChange={handleTickerCategoryChange}
                                                        onCreateOption={handleCreateCategory}
                                                        options={categoryOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    <label className='token-description-label mt-2' htmlFor="mmt_categories">MMT Categories:</label>
                                                    <CreatableSelect
                                                        id="mmt_categories"
                                                        value={formTickerData.mmt_categories}
                                                        onChange={handleMMTCategoryChange}
                                                        onCreateOption={handleCreateMMTCategory}
                                                        options={mmtCategoriesOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    />
                                                    {/* <label className='token-description-label mt-2' htmlFor="ecosystem">Ecosystems:</label>
                                                    <CreatableSelect
                                                        id="ecosystem"
                                                        value={formTickerData.ecosystem}
                                                        onChange={handleEcosystemChange}
                                                        onCreateOption={handleCreateEcosystem}
                                                        options={ecosystemsOptions}
                                                        isMulti
                                                        className="admin-select-container mt-2"
                                                        classNamePrefix="admin-select"
                                                    /> */}
                                                    <label className='token-description-label mt-3'>Verified:</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="verified"
                                                            id="verified-true"
                                                            value="true"
                                                            checked={formTickerData.verified === true}
                                                            onChange={() => setFormTickerData({ ...formTickerData, verified: true })}
                                                        />
                                                        <label className="token-description-label" htmlFor="verified-true">
                                                            True
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="verified"
                                                            id="verified-false"
                                                            value="false"
                                                            checked={formTickerData.verified === false}
                                                            onChange={() => setFormTickerData({ ...formTickerData, verified: false })}
                                                        />
                                                        <label className="token-description-label" htmlFor="verified-false">
                                                            False
                                                        </label>
                                                    </div>
                                                    <label className='token-description-label mt-3'>Fetch Status:</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="fetch_status"
                                                            id="fetch_status-true"
                                                            value="true"
                                                            checked={formTickerData.fetch_status === true}
                                                            onChange={() => setFormTickerData({ ...formTickerData, fetch_status: true })}
                                                        />
                                                        <label className="token-description-label" htmlFor="fetch_status-true">
                                                            True
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="fetch_status"
                                                            id="fetch_status-false"
                                                            value="false"
                                                            checked={formTickerData.fetch_status === false}
                                                            onChange={() => setFormTickerData({ ...formTickerData, fetch_status: false })}
                                                        />
                                                        <label className="token-description-label" htmlFor="fetch_status-false">
                                                            False
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className='token-description-label mt-2'>Ecosystems:</label>
                                                    <div className='ecosystem-table'>
                                                        <Table className='atw-table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Name</th>
                                                                    <th>Address</th>
                                                                    <th>Dex Url</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ecosystems.length > 0 ? (
                                                                    ecosystems.map((ecosystem, index) => {

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td width={'25%'}>
                                                                                    <Select
                                                                                        id={`ecosystem_select_${index}`}
                                                                                        className="admin-select-container mt-2"
                                                                                        classNamePrefix="admin-select"
                                                                                        value={{ value: ecosystem.name, label: ecosystem.name }}
                                                                                        options={ecosystemsOptions}

                                                                                        onChange={(selectedOption) => handleEcosystemChangeInTable(index, 'name', selectedOption)}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        id={`eco_address_${index}`}
                                                                                        className="form-control mt-2 ticker-text-box"
                                                                                        name="address"
                                                                                        value={ecosystem.address || ''}
                                                                                        onChange={(e) => handleEcosystemChange(index, 'address', { value: e.target.value })}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        id={`dex_url_${index}`}
                                                                                        className="form-control mt-2 ticker-text-box"
                                                                                        name="dexscreen_url"
                                                                                        value={ecosystem.dexscreen_url || ''}
                                                                                        onChange={(e) => handleEcosystemChange(index, 'dexscreen_url', { value: e.target.value })}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        className="btn btn-sm btn-danger"
                                                                                        onClick={() => handleDeleteEcosystem(index)}
                                                                                    >
                                                                                        Delete
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={5} className="text-center">
                                                                            No ecosystems available
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>

                                                        <div className="mt-3 text-right">
                                                            {ecosystems.length > 0 &&
                                                                <button
                                                                    className="btn btn-sm btn-primary ms-2"
                                                                    onClick={fetchAllDexscreenData}
                                                                >
                                                                    Fetch All
                                                                </button>
                                                            }
                                                            <button
                                                                className="btn btn-sm btn-secondary ms-2"
                                                                onClick={handleAddEcosystem}
                                                            >
                                                                Add New Ecosystem
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                s
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <button className="btn btn-primary" onClick={handleSaveTickerChanges} disabled={loading}>
                                                {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => setShowTickerUpdateModal(false)}>Close</button>
                                        </Modal.Footer>
                                    </Modal>


                                </>}
                        </Col>
                    </Row>

                </div>
            </section>
        </div>

    )
}
export default TweetList;